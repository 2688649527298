import React from 'react';
import styled from 'styled-components';
import { styles } from 'utils';

const ImageWrapper = styled.svg`
  .polygon {
    fill: ${styles.colors.mainBlue};
    fill-opacity: 1;
    transition: ${styles.transition};
  }

  .ellipse {
    fill: #4a86ff;
    fill-opacity: 1;
    stroke-width: 1.04603;
  }

  &:hover .polygon {
    fill: ${styles.colors.mainWhite};
  }
`;

const IntroBackgroundImg = ({
  title = 'Code Editor',
  width = '1126.19355',
  height = '855',
}) => {
  return (
    <ImageWrapper
      id="a594ac37-6d44-4297-8862-1cbd9c01c0b7"
      data-name="Layer 1"
      width={width}
      height={height}
      viewBox="0 0 1126.19355 855"
    >
      <title>{title}</title>
      <path
        d="M 544.86427,756.03446 C 302.46543,737.84828 13.39354,552.67983 28.11062,362.01862 42.8277,171.35741 322.79694,-6.1322392 565.19577,12.05391 807.5946,30.240059 947.12766,386.55378 932.41058,577.21497 917.6935,767.87615 787.2631,774.22064 544.86427,756.03446 Z"
        fill="#f2f2f2"
        id="path3539"
      />
      <polygon
        id="polygon3607-6"
        fill="#3f3d56"
        points="1035.152,628.862 1035.178,629.357 1028.776,751.653 1037.329,751.653 1038.355,688.485 1069.404,640.461 1038.433,683.736 1039.3,630.365 1075.391,564.338 1039.436,621.993 1039.826,597.995 1064.699,550.226 1039.929,591.645 1040.63,548.484 1067.265,510.453 1040.74,541.697 1041.178,462.556 1038.425,567.325 1038.651,563.003 1011.571,521.552 1038.217,571.3 1035.694,619.503 1035.619,618.223 1004.4,574.602 1035.524,622.743 1035.209,628.771 "
        transform="matrix(5.4590897,0,0,1.0620198,-5179.4322,-474.63316)"
      />
      <ellipse
        cx="531.96332"
        cy="818.3576"
        rx="521.31848"
        ry="32.922615"
        fill="#3f3d56"
        id="ellipse3541"
      />
      <ellipse
        cx="531.96332"
        cy="817.8266"
        rx="444.04794"
        ry="21.771406"
        opacity="0.1"
        id="ellipse3543"
      />
      <rect
        x="151.79233"
        y="187.51784"
        width="774.76581"
        height="626.59167"
        fill="#3f3d56"
        id="rect3549"
      />
      <path
        d="M 530.3608,273.14758 H 339.22678 a 2.319589,2.3910633 0 1 1 0,-4.78211 H 530.3608 a 2.319589,2.3910633 0 0 1 0,4.78211 z"
        fill="#f2f2f2"
        id="path3551"
      />
      <path
        d="M 739.58759,299.44927 H 438.96905 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 300.61854 a 2.3195906,2.391065 0 1 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3553"
      />
      <path
        d="M 531.28864,325.75094 H 340.15462 a 2.319589,2.3910633 0 1 1 0,-4.78212 h 191.13402 a 2.319589,2.3910633 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3555"
      />
      <path
        d="M 739.58759,326.22916 H 548.45358 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 191.13401 a 2.3195906,2.391065 0 1 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3557"
      />
      <path
        d="M 531.75255,352.05263 H 340.61854 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 191.13401 a 2.3195906,2.391065 0 1 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3559"
      />
      <path
        d="M 532.21647,378.3543 H 341.08245 a 2.319589,2.3910633 0 0 1 0,-4.78212 h 191.13402 a 2.319589,2.3910633 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3561"
      />
      <path
        d="m 618.96905,273.6258 h -54.74228 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 54.74228 a 2.3195906,2.391065 0 1 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3563"
      />
      <path
        d="m 618.96905,352.05263 h -54.74228 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 54.74228 a 2.3195906,2.391065 0 1 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3565"
      />
      <path
        d="m 618.96905,377.87609 h -54.74228 a 2.319589,2.3910633 0 1 1 0,-4.78212 h 54.74228 a 2.319589,2.3910633 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3567"
      />
      <path
        d="m 710.82471,377.87609 h -54.74227 a 2.319589,2.3910633 0 0 1 0,-4.78212 h 54.74227 a 2.319589,2.3910633 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3569"
      />
      <path
        d="m 393.50513,299.44927 h -54.74226 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 54.74226 a 2.3195906,2.391065 0 0 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3571"
      />
      <path
        d="M 530.59276,660.49959 H 339.45874 a 2.319589,2.3910633 0 0 1 0,-4.78212 h 191.13402 a 2.319589,2.3910633 0 0 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3573"
      />
      <path
        d="M 529.66493,713.10296 H 338.53091 a 2.3195906,2.391065 0 1 1 0,-4.78213 h 191.13402 a 2.3195906,2.391065 0 0 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3575"
      />
      <path
        d="M 530.12884,739.40463 H 338.99483 a 2.319589,2.3910633 0 1 1 0,-4.78212 h 191.13401 a 2.319589,2.3910633 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3577"
      />
      <path
        d="m 611.77832,713.58117 h -54.74227 a 2.319589,2.3910633 0 0 1 0,-4.78212 h 54.74227 a 2.319589,2.3910633 0 0 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3579"
      />
      <path
        d="m 611.77832,739.40463 h -54.74227 a 2.3195858,2.39106 0 0 1 0,-4.78212 h 54.74227 a 2.3195858,2.39106 0 1 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3581"
      />
      <path
        d="m 703.63399,713.58117 h -54.74226 a 2.319589,2.3910633 0 1 1 0,-4.78212 h 54.74226 a 2.319589,2.3910633 0 0 1 0,4.78212 z"
        fill="#f2f2f2"
        id="path3583"
      />
      <path
        d="M 739.81956,686.80128 H 439.20101 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 300.61855 a 2.3195906,2.391065 0 0 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3585"
      />
      <path
        d="m 393.73709,686.80128 h -54.74226 a 2.3195906,2.391065 0 0 1 0,-4.78213 h 54.74226 a 2.3195906,2.391065 0 0 1 0,4.78213 z"
        fill="#f2f2f2"
        id="path3587"
      />
      <path
        d="M 739.81956,766.18452 H 439.20101 a 2.319589,2.3910633 0 1 1 0,-4.78211 h 300.61855 a 2.3195839,2.391058 0 0 1 0,4.78211 z"
        fill="#f2f2f2"
        id="path3589"
      />
      <path
        d="m 393.73709,766.18452 h -54.74226 a 2.319589,2.3910633 0 1 1 0,-4.78211 h 54.74226 a 2.319589,2.3910633 0 1 1 0,4.78211 z"
        fill="#f2f2f2"
        id="path3591"
      />
      <polygon
        transform="matrix(1.0302737,0,0,1.0620198,-50.340705,15.470625)"
        points="519.06,543.42 535.27,527.21 480.786,472.725 535.72,417.79 519.06,401.13 447.014,472.275 "
        id="polygon3593"
        className="polygon"
      />
      <polygon
        transform="matrix(1.0302737,0,0,1.0620198,-50.340705,15.470625)"
        points="625.327,543.42 609.117,527.21 663.602,472.725 608.667,417.79 625.327,401.13 697.373,472.275 "
        id="polygon3595"
        className="polygon"
      />
      <ellipse
        ry="9.5581789"
        rx="9.2724628"
        cx="172.3978"
        cy="203.44814"
        fill="#6c63ff"
        id="circle3597"
        className="ellipse"
      />
      <ellipse
        ry="9.5581789"
        rx="9.2724628"
        cx="197.12437"
        cy="203.44814"
        fill="#6c63ff"
        id="circle3599"
        className="ellipse"
      />
      <ellipse
        ry="9.5581789"
        rx="9.2724628"
        cx="221.85092"
        cy="203.44814"
        fill="#6c63ff"
        id="circle3601"
        className="ellipse"
      />
      <rect
        x="151.79233"
        y="219.37845"
        width="774.76581"
        height="3.1860595"
        opacity="0.1"
        id="rect3603"
      />
      <path
        d="m 1109.9468,660.59836 c 0,67.72615 -39.0585,91.3736 -87.2398,91.3736 -48.1812,0 -87.23979,-23.64745 -87.23979,-91.3736 0,-67.72615 87.23979,-153.88455 87.23979,-153.88455 0,0 87.2398,86.1584 87.2398,153.88455 z"
        fill="#f2f2f2"
        id="path3605"
      />
      <polygon
        transform="matrix(1.0302737,0,0,1.0620198,-50.340705,15.470625)"
        points="1037.329,751.653 1038.355,688.485 1069.404,640.461 1038.433,683.736 1039.3,630.365 1075.391,564.338 1039.436,621.993 1039.826,597.995 1064.699,550.226 1039.929,591.645 1040.63,548.484 1067.265,510.453 1040.74,541.697 1041.178,462.556 1038.425,567.325 1038.651,563.003 1011.571,521.552 1038.217,571.3 1035.694,619.503 1035.619,618.223 1004.4,574.602 1035.524,622.743 1035.209,628.771 1035.152,628.862 1035.178,629.357 1028.776,751.653 "
        fill="#3f3d56"
        id="polygon3607"
      />

      {/* <path
        id="path3539"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M581.7675,778.43794C339.36866,759.99137,50.29677,572.17166,65.01385,378.78054S359.70017,5.35846,602.099,23.805,984.03089,403.667,969.31381,597.05809,824.16633,796.88451,581.7675,778.43794Z"
      />
      <ellipse
        id="ellipse3541"
        fill="#3f3d56"
        ry="31"
        rx="506"
        cy="756"
        cx="565.19355"
      />
      <ellipse
        id="ellipse3543"
        opacity="0.1"
        ry="20.5"
        rx="431"
        cy="755.5"
        cx="565.19355"
      />
      <rect
        id="rect3549"
        fill="#3f3d56"
        height="590"
        width="752"
        y="162"
        x="196.19355"
      />
      <path
        id="path3551"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M600.54135,265.12914H415.02363a2.25143,2.25143,0,1,1,0-4.50285H600.54135a2.25143,2.25143,0,0,1,0,4.50285Z"
      />
      <path
        id="path3553"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M803.6202,289.89486H511.83506a2.25143,2.25143,0,0,1,0-4.50286H803.6202a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3555"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M601.44192,314.66057H415.9242a2.25143,2.25143,0,1,1,0-4.50286H601.44192a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3557"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M803.6202,315.11086H618.10249a2.25143,2.25143,0,0,1,0-4.50286H803.6202a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3559"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M601.8922,339.42629H416.37449a2.25143,2.25143,0,0,1,0-4.50286H601.8922a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3561"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M602.34249,364.192H416.82477a2.25143,2.25143,0,0,1,0-4.50286H602.34249a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3563"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M686.54592,265.57943H633.4122a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3565"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M686.54592,339.42629H633.4122a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,1,1,0,4.50286Z"
      />
      <path
        id="path3567"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M686.54592,363.74171H633.4122a2.25143,2.25143,0,1,1,0-4.50285h53.13372a2.25143,2.25143,0,1,1,0,4.50285Z"
      />
      <path
        id="path3569"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M775.70249,363.74171H722.56877a2.25143,2.25143,0,0,1,0-4.50285h53.13372a2.25143,2.25143,0,1,1,0,4.50285Z"
      />
      <path
        id="path3571"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M467.70706,289.89486H414.57335a2.25143,2.25143,0,0,1,0-4.50286h53.13371a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3573"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M600.76649,629.86057H415.24877a2.25143,2.25143,0,0,1,0-4.50286H600.76649a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3575"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M599.86592,679.392H414.3482a2.25143,2.25143,0,1,1,0-4.50286H599.86592a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3577"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M600.3162,704.15771H414.79849a2.25143,2.25143,0,1,1,0-4.50285H600.3162a2.25143,2.25143,0,1,1,0,4.50285Z"
      />
      <path
        id="path3579"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M679.56649,679.84229H626.43277a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3581"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M679.56649,704.15771H626.43277a2.25142,2.25142,0,0,1,0-4.50285h53.13372a2.25142,2.25142,0,1,1,0,4.50285Z"
      />
      <path
        id="path3583"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M768.72306,679.84229H715.58935a2.25143,2.25143,0,1,1,0-4.50286h53.13371a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3585"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M803.84535,654.62629H512.0602a2.25143,2.25143,0,0,1,0-4.50286H803.84535a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3587"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M467.9322,654.62629H414.79849a2.25143,2.25143,0,0,1,0-4.50286H467.9322a2.25143,2.25143,0,0,1,0,4.50286Z"
      />
      <path
        id="path3589"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M803.84535,729.37371H512.0602a2.25143,2.25143,0,1,1,0-4.50285H803.84535a2.25142,2.25142,0,0,1,0,4.50285Z"
      />
      <path
        id="path3591"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M467.9322,729.37371H414.79849a2.25143,2.25143,0,1,1,0-4.50285H467.9322a2.25143,2.25143,0,1,1,0,4.50285Z"
      />
      <polygon
        className="polygon"
        id="polygon3593"
        points="519.06 401.13 447.014 472.275 519.06 543.42 535.27 527.21 480.786 472.725 535.72 417.79 519.06 401.13"
      />
      <polygon
        className="polygon"
        id="polygon3595"
        points="625.327 401.13 697.373 472.275 625.327 543.42 609.117 527.21 663.602 472.725 608.667 417.79 625.327 401.13"
      />
      <circle
        className="polygon"
        id="circle3597"
        r="9"
        cy="177"
        cx="216.19355"
      />
      <circle
        className="polygon"
        id="circle3599"
        r="9"
        cy="177"
        cx="240.19355"
      />
      <circle
        className="polygon"
        id="circle3601"
        r="9"
        cy="177"
        cx="264.19355"
      />
      <rect
        id="rect3603"
        opacity="0.1"
        height="3"
        width="752"
        y="192"
        x="196.19355"
      />
      <path
        id="path3605"
        fill="#f2f2f2"
        transform="translate(-36.90323 -22.5)"
        d="M1163.09677,629.95357c0,63.77108-37.91089,86.03756-84.67635,86.03756s-84.67635-22.26648-84.67635-86.03756,84.67635-144.898,84.67635-144.898S1163.09677,566.18249,1163.09677,629.95357Z"
      />
      <polygon
        id="polygon3607"
        fill="#3f3d56"
        points="1038.433 683.736 1039.3 630.365 1075.391 564.338 1039.436 621.993 1039.826 597.995 1064.699 550.226 1039.929 591.645 1039.929 591.645 1040.63 548.484 1067.265 510.453 1040.74 541.697 1041.178 462.556 1038.425 567.325 1038.651 563.003 1011.571 521.552 1038.217 571.3 1035.694 619.503 1035.619 618.223 1004.4 574.602 1035.524 622.743 1035.209 628.771 1035.152 628.862 1035.178 629.357 1028.776 751.653 1037.329 751.653 1038.355 688.485 1069.404 640.461 1038.433 683.736"
      /> */}
    </ImageWrapper>
  );
};

export default IntroBackgroundImg;
